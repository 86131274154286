import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  FormHelperText,
  Slide,
  Center,
  Input,
} from "@chakra-ui/react";

import React from "react";

import { QuijenDropzone } from "./dropzone";

export default function Slide1({ topic, setTopic, setPdf, index }) {
  return (
    <Slide
      in={(index === 1).toString()}
      out={(index !== 1).toString()}
      style={{ position: "relative" }}
      direction="right"
    >
      <Center>
        <Box mt={"50px"} width={["95%", "80%"]}>
          <FormControl>
            <Heading size="lg" mb={"10px"}>
              <Center>What is the topic of the quiz?</Center>
            </Heading>
            <Input
              onChange={(e) => setTopic(e.target.value)}
              value={topic}
              variant={"outline"}
              fontSize={["md"]}
              style={{ textAlign: "center" }}
              _placeholder={{ textAlign: "center" }}
              placeholder="e.g. Algebra story problems, Hair in the 1980s"
              maxLength={100}
            />
            <FormHelperText fontSize={["sm"]}>
              <Center>
                You can be as detailed or as simplistic as you like. If no topic
                is provided, we will magically create one.
              </Center>
            </FormHelperText>
          </FormControl>
        </Box>
      </Center>
      <Center>
        <Box mt={"50px"} width={["95%", "80%", "80%"]}>
          <FormControl>
            <FormLabel fontSize={"sm"}>
              <Center>(Optional) Upload Class Materials</Center>
            </FormLabel>
            <QuijenDropzone setPdf={setPdf} />
          </FormControl>
        </Box>
      </Center>
    </Slide>
  );
}
