import React from "react";
import { Box } from "@chakra-ui/react";

import MultipleChoiceQ from "./multipleChoiceQ";
import TrueFalseQ from "./trueFalseQ";
import FiibQ from "./fiibQ";

export const Quiz = ({ quizData, setQuiz }) => {
  return (
    <>
      <Box p={"25px"} id="quizOutput">
        {quizData?.questions?.map((question, index) => {
          if (question.type == "multipleChoice")
            return (
              <MultipleChoiceQ
                question={question}
                index={index}
                key={index}
                quiz={quizData}
                setQuiz={setQuiz}
              />
            );
          else if (question.type == "trueFalse")
            return (
              <TrueFalseQ
                question={question}
                index={index}
                key={index}
                quiz={quizData}
                setQuiz={setQuiz}
              />
            );
            else if (question.type == "fillIn")
              return (
                <FiibQ
                  question={question}
                  index={index}
                  key={index}
                  quiz={quizData}
                  setQuiz={setQuiz}
                />
              );
        })}
      </Box>
    </>
  );
};
