import { Box } from "@chakra-ui/layout";
import Button from "../button";
import { Center, Stack } from "@chakra-ui/react";

const StudentLevel = ({ setLevel, level, nextStep, prevStep, setFirstUse }) => {
  const handleClick = (level) => {
    setFirstUse(false);
    setLevel(level);
    nextStep();
  };

  return (
    <>
      <Center>
        <Box>
          <Stack spacing={4} wrap="wrap" alignContent={"center"}>
            <Button onClick={() => handleClick("elementary")}>
              Elementary
            </Button>
            <Button onClick={() => handleClick("middle")}>Middle School</Button>
            <Button onClick={() => handleClick("high")}>High School</Button>
            <Button onClick={() => handleClick("undergraduate")}>
              Undergraduate
            </Button>
            <Button onClick={() => handleClick("graduate")}>Graduate</Button>
            <Button onClick={() => handleClick("doctoral")}>
              Doctoral Candidate
            </Button>
          </Stack>

          {/*
        <RadioGroup onChange={setLevel} value={level}>
          <Stack>
            
            <Radio defaultChecked value="elementary">Elementary</Radio>
            <Radio value="middle">Middle School</Radio>
            <Radio value="high">High School</Radio>
            <Radio value="undergraduate">Undergraduate</Radio>
            <Radio value="graduate">Graduate</Radio>
            <Radio value="doctoral">Doctoral Candidate</Radio>
          </Stack>
        </RadioGroup>
        */}
        </Box>
      </Center>
    </>
  );
};

export default StudentLevel;
