import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Tag,
  Link,
} from "@chakra-ui/react";
import moment from "moment";
import axios from "axios";

export const SubscriptionModal = ({
  cancelModalisOpen,
  onCancelModalToggle,
  subscriptionDetails,
  setSubscriptionDetails,
  user,
}) => {
  const cancelSubscription = () => {
    axios
      .post(process.env.REACT_APP_BACKEND_URL + "/user/subscription/cancel", {
        user: {
          id: user?.id,
          email: user?.primaryEmailAddress.emailAddress,
          name: user?.fullName,
        },
      })
      .then((response) => {
        setSubscriptionDetails({ ...subscriptionDetails, renewal: false })
        onCancelModalToggle();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const restartSubscription = () => {
    axios
      .post(process.env.REACT_APP_BACKEND_URL + "/user/subscription/restart", {
        user: {
          id: user?.id,
          email: user?.primaryEmailAddress.emailAddress,
          name: user?.fullName,
        },
      })
      .then((response) => {
        setSubscriptionDetails({ ...subscriptionDetails, renewal: true })
        onCancelModalToggle();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const momentObject = moment.unix(
    subscriptionDetails.ends ? parseInt(subscriptionDetails.ends) : 0
  );
  const formattedDate = momentObject.format("MMMM Do, YYYY");
  const shortDate = momentObject.format("MM/DD");

  console.log(subscriptionDetails.renewal);

  return (
    <>
      <Tag aria-label="Subscribed" variant="solid" colorScheme="green" ml={3}>
        <Link
          color={"white"}
          textDecoration={"none"}
          onClick={() => onCancelModalToggle()}
        >
           {subscriptionDetails.renewal == false ? "Sub Ends " + shortDate : "Subscribed"}
        </Link>
      </Tag>
      <Modal isOpen={cancelModalisOpen} onClose={onCancelModalToggle}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cancel Subscription</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {formattedDate && (
              <Text>
                Your subscription{" "}
                {subscriptionDetails.renewal ? "renews" : "ends"} on{" "}
                <b>{formattedDate}</b>.
              </Text>
            )}
            {(subscriptionDetails.renewal && (
              <>
                <Text mb={"5"}>Would you like to cancel and stop renewal?</Text>
                <Text>
                  Your subscription will end on the date listed above and you
                  will not be charged again.
                </Text>
              </>
            )) || (
              <>
                <Text mb={"5"}>
                  Would you like to restart the subscription?
                </Text>
                <Text>
                  If you restart your subscription, it will renew on the date
                  listed above.
                </Text>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            {subscriptionDetails.renewal && (
              <Button
                backgroundColor={"red"}
                color={"white"}
                onClick={() => cancelSubscription(user)}
                mr={3}
              >
                Cancel Subscription
              </Button>
            )}
            {!subscriptionDetails.renewal && (
              <Button
                backgroundColor={"green"}
                color={"white"}
                onClick={() => restartSubscription(user)}
                mr={3}
              >
                Restart
              </Button>
            )}
            <Button backgroundColor={"gray.400"} onClick={onCancelModalToggle}>
              Nevermind
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
