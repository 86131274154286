import * as React from "react";
import { useState, createContext } from "react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import "./App.css";
import { StepsTheme as Steps } from "chakra-ui-steps";
import Wizard from "./components/wizard";
import ThreeTierPricing from "./components/ui/pricing";
import Support from "./components/ui/support";
import Terms from "./components/ui/terms";
import Privacy from "./components/ui/privacy";
import Nav from "./components/nav";
import About from "./components/ui/about/about";
// import { GoogleOAuthProvider } from "@react-oauth/google";
import "typeface-roboto";
import { ClerkProvider, GoogleOneTap } from "@clerk/clerk-react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const clerkKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY

const theme = extendTheme({
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "4rem",
  },
  fonts: {
    body: "Roboto",
    heading: "Roboto",
    mono: "Roboto",
  },
  components: {
    Steps,
    Link: {
      baseStyle: {
        textDecoration: "underline",
        color: "blue.500",
        _hover: {
          textDecoration: "none",
        },
      },
    },
    Radio: {
      baseStyle: {
        size: "lg",
        control: {
          _checked: {
            bg: "blue.500",
            borderColor: "blue.500",
          },
        },
      },
    },
  },
});

export const SubscriptionContext = createContext();

function App() {
  if (module.hot && process.env.NODE_ENV !== "production") {
    module.hot.accept();
  }

  const [subscribed, setSubscribed] = useState(false);

  return (
    <ClerkProvider publishableKey={clerkKey} afterSignOutUrl="/">
      <GoogleOneTap />
      <BrowserRouter>
        <ChakraProvider theme={theme}>
          <ToastContainer
            autoClose={3000}
            position="top-center"
            hideProgressBar={true}
            closeOnClick
          />
          <SubscriptionContext.Provider value={{ subscribed, setSubscribed }}>
            <Nav />
            <Routes>
              <Route path="/" element={<Wizard variant={"simple"} />} />
              <Route path="/pricing" element={<ThreeTierPricing />} />
              <Route path="/support" element={<Support />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/about" element={<About />} />
            </Routes>
          </SubscriptionContext.Provider>
        </ChakraProvider>
      </BrowserRouter>
    </ClerkProvider>
  );
}

export default App;
