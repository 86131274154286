import React from "react";
import { Hero } from "./components/Hero/Hero";
import { Features } from "./components/Features/Features";
import { About } from "./components/About/About";
import { Contact } from "./components/Contact/Contact";

import "./App.css";

export default function App() {
  React.useEffect(() => {
    const handleSmoothScroll = (e) => {
      const href = e.target.getAttribute("href");
      if (href?.startsWith("#")) {
        const element = document.querySelector(href);
        if (element) {
          e.preventDefault();
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    };

    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", handleSmoothScroll);
    });

    return () => {
      document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
        anchor.removeEventListener("click", handleSmoothScroll);
      });
    };
  }, []);

  return (
    <>
      <Hero />
      <Features />
      <About />
      <Contact />
    </>
  );
}
