import React from 'react';
import { useInView } from 'react-intersection-observer';
import './Features.css';

export function FeatureCard({ icon, title, description }) {
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  return (
    <div 
      ref={ref}
      className={`feature-card ${inView ? 'feature-card-visible' : ''}`}
    >
      <div className="feature-icon">
        {icon}
      </div>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
}