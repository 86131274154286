import {
  Box,
  Heading,
  Center,
  HStack,
  VStack,
  StackItem,
  Select,
  Slide,
  Text,
  Button as ChakraButton,
} from "@chakra-ui/react";
import Button from "../button";
import { Link } from "react-router-dom";
import { states } from "../utl";

import { useState } from "react";
import { MdOutlineArrowRight, MdOutlineArrowDropDown } from "react-icons/md";

export default function Slide3({
  difficulty,
  setDifficulty,
  index,
  prevStep,
  nextStep,
  subscribed,
  readingLevel,
  setReadingLevel,
  stateStandards,
  setStateStandards,
  level,
}) {
  const handleClick = (difficulty) => {
    setDifficulty(difficulty);
    nextStep();
  };

  const [advancedSettingsOpen, setAdvancedSettingsOpen] = useState(false);

  function getReadingOptions() {
    console.log(level);
    if (level == "elementary")
      return (
        <>
          <option key="1st grade" value="1st grade">
            1st Grade
          </option>
          <option key="2nd grade" value="2nd grade">
            2nd Grade
          </option>
          <option key="3rd grade" value="3rd grade">
            3rd Grade
          </option>
          <option key="4th grade" value="4th grade">
            4th Grade
          </option>
          <option key="5th grade" value="5th grade">
            5th Grade
          </option>
          <option key="6th grade" value="6th grade">
            6th Grade
          </option>
        </>
      );
    else if (level == "middle")
      return (
        <>
          <option key="7th grade" value="7th grade">
            7th Grade
          </option>
          <option key="8th grade" value="8th grade">
            8th Grade
          </option>
        </>
      );
    else if (level == "high")
      return (
        <>
          <option key="9th grade" value="9th grade">
            9th Grade
          </option>
          <option key="10th grade" value="10th grade">
            10th Grade
          </option>
          <option key="11th grade" value="11th grade">
            12th Grade
          </option>
        </>
      );
    else
      return (
        <option key="college" value="college">
          College
        </option>
      );
  }

  function getStateStandardsOptions() {
    return states.map((state) => {
      return (
        <option key={state} value={state}>
          {state}
        </option>
      );
    });
  }

  return (
    <Slide
      in={(index === 3).toString()}
      out={(index !== 3).toString()}
      style={{ position: "relative" }}
      direction="right"
    >
      <Box mb={"50px"}>
        <Box mt={"25px"} mb={"25px"}>
          <Center>
            <Heading size={"lg"}>Last but not least...</Heading>
          </Center>
          <Center>
            <Heading mt={"15px"} size={"sm"}>
              Select a general difficulty level...
            </Heading>
          </Center>
        </Box>
        <Center>
          <HStack>
            <Button onClick={() => handleClick("easy")}>Easy</Button>
            <Button onClick={() => handleClick("medium")}>Medium</Button>
            <Button onClick={() => handleClick("hard")}>Hard</Button>
          </HStack>
        </Center>
        {/*
        <Center>
          <RadioGroup onChange={setDifficulty} value={difficulty}>
            <Stack>
              <Radio value="easy">Easy</Radio>
              <Radio value="medium">Medium</Radio>
              <Radio value="hard">Hard</Radio>
            </Stack>
          </RadioGroup>
        </Center>
        */}
        <Center>
          <Box mt={"25px"}>
            <ChakraButton
              onClick={() => setAdvancedSettingsOpen(!advancedSettingsOpen)}
              leftIcon={
                advancedSettingsOpen ? (
                  <MdOutlineArrowDropDown />
                ) : (
                  <MdOutlineArrowRight />
                )
              }
            >
              Advanced Settings
            </ChakraButton>
          </Box>
        </Center>
        {advancedSettingsOpen && (
          <>
            {!subscribed && (
              <>
                <Box mt={"25px"} mb={"25px"}>
                  <Center>
                    <Text fontSize={["md", "lg"]}>
                      <Link
                        to="/pricing"
                        style={{
                          color: "rgb(49, 130, 206)",
                          textDecoration: "underline",
                        }}
                      >
                        Subscribe
                      </Link>{" "}
                      to unlock advanced settings.
                    </Text>
                  </Center>
                </Box>
              </>
            )}
            <Box mt={"15px"} pl={"5%"} pr={"5%"}>
              <Box>
                <Center>
                  <Box width={["auto", "100%"]} mt={"15px"}>
                    <Center>
                      <Box>Reading Level:</Box>
                    </Center>
                    <Center>
                      <Box width={["100%", "auto"]} mr={"5px"}>
                        <Select
                          disabled={!subscribed}
                          onChange={(e) => setReadingLevel(e.target.value)}
                          value={readingLevel}
                          display={"inline"}
                          style={{ display: "inline" }}
                        >
                          {getReadingOptions()}
                        </Select>
                      </Box>
                    </Center>
                  </Box>
                </Center>
                <Center>
                  <Box mt={"25px"}>
                    <Box width={["100%"]}>
                      <Center>
                        <Box>
                         State Content Standards:
                        </Box>
                      </Center>
                      <Center>
                        <Select
                          disabled={!subscribed}
                          display={"inline"}
                          width={["100%"]}
                          mb={"5px"}
                          style={{ display: "inline" }}
                          onChange={(e) => setStateStandards(e.target.value)}
                          value={stateStandards}
                        >
                          {getStateStandardsOptions()}
                        </Select>
                      </Center>
                    </Box>
                  </Box>
                </Center>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Slide>
  );
}
