import axios from "axios";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

const getFingerprint = () => {
  return new Promise((resolve) => {
    const fpPromise = FingerprintJS.load();
    fpPromise.then((fp) => {
      fp.get().then((result) => {
        resolve(result.visitorId);
      });
    });
  });
};

export async function GetGens(setGens) {
  getFingerprint().then((fingerprint) => {
    axios
      .post(process.env.REACT_APP_BACKEND_URL + "/user/checkQuizGen", {
        fingerprint,
      })
      .then((response) => {
        setGens(response.data);
      });
  });
}

export function IncrementGens(setGens) {
  getFingerprint().then((fingerprint) => {
    axios
      .post(process.env.REACT_APP_BACKEND_URL + "/user/incrementQuizGen", {
        fingerprint,
      })
      .then((response) => {
        setGens(response.data);
      });
  });
}
