import React, { useContext } from "react";
import { Box, Heading } from "@chakra-ui/react";
import ContentEditable from "react-contenteditable";
import { SubscriptionContext } from "../../App";
import { getLetterFromNumber } from "../utl";

export default function MultipleChoiceQ({
  question,
  index,
  quiz,
  setQuiz,
}) {
  let questionHeading = question.question
  const { subscribed, setSubscribed } = useContext(SubscriptionContext)

  function handleQuestionChange(e, questionIndex) {
    question.question = e.target.value;

    setQuiz({
      ...quiz,
      questions: [
        ...quiz.questions.slice(0, questionIndex),
        question,
        ...quiz.questions.slice(questionIndex + 1),
      ],
    });
  }

  function handleAnswerChange(e, answerIndex) {
    question.answers[answerIndex] = e.target.value

    setQuiz({
      ...quiz,
      questions: [
        ...quiz.questions.slice(0, index),
        question,
        ...quiz.questions.slice(index + 1),
      ],
    })
  }

  return (
    <Box key={index} mb={"25px"}>
      <Heading size="md">
        {index + 1}.{" "}
        <ContentEditable
          html={questionHeading}
          disabled={!subscribed}
          tagName="span"
          onChange={(e) => handleQuestionChange(e, index)}
        />
      </Heading>
      {question.answers.map((answer, answerIndex) => {
        const letter = getLetterFromNumber(answerIndex + 1);

        return (
          <Box key={`question ${index} answer ${answerIndex}`}>
            {letter}. - {" "}
            <ContentEditable
              html={answer.toString()}
              key={`question ${index} answer ${answerIndex}`}
              onChange={(e) => handleAnswerChange(e, answerIndex)}
              disabled={!subscribed}
              tagName="span"
            />
          </Box>
        );
      })}
    </Box>
  );
}
