import React from 'react';
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { Center, Link } from "@chakra-ui/react";
import './Contact.css';

export function Contact() {
  return (
    <section id="contact" className="contact">
      <h2>We Value Your Feedback</h2>
      <p>Have suggestions or need help? We'd love to hear from you! <br /><br />Contact us at <a href="mailto:help@quijen.com">help@quijen.com</a></p>
      <Center>
          <Link href="https://x.com/QuijenSocial" target="_blank">
            <FaSquareXTwitter size={50} />
          </Link>
          <Link href="https://instagram.com/quijen25" target="_blank" ml={4}>
            <FaInstagram size={50} />
          </Link>
        </Center>
    </section>
    
  );
}