import React, { useMemo, useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import {
  Box,
  Center,
  Button,
  FormHelperText,
  HStack,
  Link,
  List,
  ListItem,
  ListIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  Text,
  StackItem,
  Tooltip,
  ModalBody,
} from "@chakra-ui/react";

import { TiUploadOutline } from "react-icons/ti";
import { MdDeleteForever, MdCheckCircle } from "react-icons/md";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const baseStyle = {
  position: "relative",
  height: "100px",
  color: "grey",
  lineHeight: "55px",
  verticalAlign: "middle",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "gray",
  borderStyle: "dashed",
  backgroundColor: "rgb(237, 242, 247)",
  outline: "none",
  width: "100%",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};

const focusedStyle = {
  borderColor: "#2196f3",
  cursor: "pointer",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export function QuijenDropzone({ setPdf }) {
  const [files, setFiles] = useState([]);
  const [fileModalisOpen, setFileModalisOpen] = useState(false);

  const notify = () =>
    toast("This file is too large or it is not a supported format.", {
      position: "top-center",
      autoClose: 5000,
      type: "error",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        setFiles([...files, { contents: "", filename: file.name }]);
        setPdf(file);
      });
    },
    [files]
  );

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      disabled: files.length === 1,
      accept: {
        "application/pdf": [".pdf"],
        "application/msword": [".docx"],
        "application/msword": [".doc"],
        "application/vnd.ms-excel": [".xlsx"],
        "application/vnd.ms-powerpoint": [".pptx"],
        "audio/mpeg": [".mp3"],
        "image/png": [".png"],
        "image/jpeg": [".jpg"],
        "image/gif": [".gif"],
        "text/html": [".html"],
        "text/plain": [".txt"],
      },
      maxSize: 32000000,
      onDropRejected: () => {
        notify();
      },
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const removeFIle = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <Center>
          {files.length > 0 ? (
            <>
              <Box>
                {files.length} File Uploaded.
                {files.length === 1 ? "  Maximum Reached." : ""}
              </Box>
            </>
          ) : (
            <HStack>
              <StackItem>
                <TiUploadOutline />
              </StackItem>
              <StackItem fontSize={["10pt", "12pt"]}>
                Drag and Drop or Click to Upload Files
              </StackItem>
            </HStack>
          )}
        </Center>
      </div>
      <Center>
        <HStack mt={"10px"}>
          {files.length > 0
            ? files.map((file, index) => {
                return (
                  <StackItem key={index}>
                    <Button
                      onClick={() => {
                        removeFIle(index);
                      }}
                      fontSize={"10pt"}
                      variant="outline"
                      colorScheme={"red"}
                      leftIcon={<MdDeleteForever />}
                    >
                      Remove '{file.filename}'
                    </Button>
                  </StackItem>
                );
              })
            : ""}
        </HStack>
      </Center>
      {files.length === 0 ? (
        <FormHelperText>
          <Center>
            <Box>
              <Text fontSize={["sm"]}>
                Upload a study guide, handwritten notes, an audiobook, or other
                materials. It will be the <b>source</b> of what the quiz taker
                will need to know.  <Link onClick={() => setFileModalisOpen(true)}>
                  Supported File Types
                </Link>
              </Text>
              <Modal
                isOpen={fileModalisOpen}
                onClose={() => setFileModalisOpen(false)}
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Using Files</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Text>
                      If you upload a file, we will do our best to ensure your
                      quiz adheres to the material you provide.
                      <Text mt={"10px"}>The file size limit is 32MB.</Text>
                      <Text mt={"10px"}>
                        {" "}
                        If you upload audio or image files, we will
                        convert/transcribe them into text.
                      </Text>
                      <Text mt={"10px"}>Supported File Types:</Text>
                      <List mt={"10px"} ml={"35px"}>
                        <ListItem>
                          <ListIcon as={MdCheckCircle} color="green.500" />
                          PDF
                        </ListItem>
                        <ListItem>
                          <ListIcon as={MdCheckCircle} color="green.500" />
                          Word Document (.doc, .docx)
                        </ListItem>
                        <ListItem>
                          <ListIcon as={MdCheckCircle} color="green.500" />
                          HTML Document (.html, .htm)
                        </ListItem>
                        <ListItem>
                          <ListIcon as={MdCheckCircle} color="green.500" />
                          Text (txt)
                        </ListItem>
                        <ListItem>
                          <ListIcon as={MdCheckCircle} color="green.500" />
                          Spreadsheets (.xls, .xlsx, .csv, .tsv)
                        </ListItem>
                        <ListItem>
                          <ListIcon as={MdCheckCircle} color="green.500" />
                          PowerPoint Presentation (.ppt, .pptx)
                        </ListItem>
                        <ListItem>
                          <ListIcon as={MdCheckCircle} color="green.500" />
                          Image (.jpg, .png, .gif)
                        </ListItem>
                        <ListItem>
                          <ListIcon as={MdCheckCircle} color="green.500" />
                          Audio (.mp3, .wav, .ogg)
                        </ListItem>
                      </List>
                    </Text>
                    <Button
                      mt={"35px"}
                      w={"100%"}
                      colorScheme={"gray"}
                      onClick={() => setFileModalisOpen(false)}
                    >
                      Ok, thanks!
                    </Button>
                  </ModalBody>
                </ModalContent>
              </Modal>
            </Box>
          </Center>
        </FormHelperText>
      ) : (
        ""
      )}
    </div>
  );
}
