import { Flex, Button, Box } from "@chakra-ui/react";

const WizNav = ({ hasCompletedAllSteps, reset, activeStep, prevStep, nextStep, isLastStep }) => {
    return ( <Flex width="100%" justify="center" gap={4} mt={"55px"} mb={"55px"}>
        {hasCompletedAllSteps ? (
          <Button size="lg" fontSize={{ base: "lg", md: "2xl" }} onClick={reset}>
            Reset
          </Button>
        ) : (
          <Box display={activeStep === 0 ? "none" : "block"} mt={"25px"}>
            <Button
              isDisabled={activeStep === 0}
              onClick={prevStep}
              size="lg"
              fontSize={{ base: "lg", md: "2xl" }}
              colorScheme="yellow"
              backgroundColor="yellow.500"
              color="white"
              mr={2}
            >
              Go Back
            </Button>
            <Button
              display={activeStep === 3 ? "none" : ""}
              size="lg"
              fontSize={{ base: "lg", md: "2xl" }}
              colorScheme="green"
              onClick={nextStep}
              disabled={activeStep === 0}
            >
              {isLastStep ? "Finish" : "Next"}
            </Button>
          </Box>
      )}
    </Flex>
  );
};

export default WizNav;
