import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import lodash from "lodash";
import { getLetterFromNumber } from "../utl";

Font.register({
  family: "Lato",
  src: `https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wWw.ttf`,
});

Font.register({
  family: "Lato Bold",
  src: `https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf`,
});

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: "Lato",
    flexDirection: "row",
  },
  question: {
    fontFamily: "Lato Bold",
    color: "black",
    fontSize: 14,
    fontWeight: "bold",
  },
  answer: {
    color: "black",
    fontSize: 12,
  },
  body: {
    margin: 10,
    padding: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

export default function PDFQuiz({ quizData }) {
  const pages = lodash.chunk(quizData?.questions, 5);

  return (
    <Document style={styles.body}>
      {pages.map((page, index) => (
        <Page size="A4" style={styles.page} key={index + " page"}>
          <View style={styles.section} key={index + " section"}>
            {page.map((question, index) => {
              return (
                <>
                  <Text style={styles.question} key={index+" question"}>
                    {index + 1}. {question.question}
                  </Text>
                  <View style={styles.section} key={`answers ${index}`}>
                    {question.type == "multipleChoice" &&
                      question.answers.map((answer, answerIndex) => {
                       const letter = getLetterFromNumber(answerIndex + 1);

                        return (
                          <Text
                            style={styles.answer}
                            key={index + " answer choice " + answerIndex}
                          >
                            {letter}. - {answer}
                          </Text>
                        );
                      })}
                    {question.type == "trueFalse" && (
                      <>
                        <Text
                          style={styles.answer}
                          key={`answer ${index} True`}
                        >
                          A. - True
                        </Text>
                        <Text
                          style={styles.answer}
                          key={`answer ${index} False`}
                        >
                          B. - False
                        </Text>
                      </>
                    )}
                     {question.type == "fillIn" && (
                      <>
                        <Text
                          style={styles.answer}
                          key={`answer ${index} Fill-In`}
                        >
                          _______________________________
                        </Text>
                      </>
                    )}
                  </View>
                </>
              );
            })}
          </View>
        </Page>
      ))}
    </Document>
  );
}
