import { Document, Packer, Paragraph, TextRun } from "docx";
import { saveAs } from "file-saver";
import { getLetterFromNumber } from "../utl";

const SaveDoc = (quizData) => {
  const docChildren = [];

  for (let i = 0; i < quizData.questions.length; i++) {
    if (i > 0) {
      docChildren.push(
        new Paragraph({
          children: [new TextRun({ break: 1, text: "\n" })],
        })
      );
    }

    docChildren.push(
      new Paragraph({
        pageBreakBefore: i % 7 === 0 ? true : false,
        children: [
          new TextRun({
            text: "" + (i + 1) + ". " + quizData.questions[i].question,
            bold: true,
          }),
        ],
      })
    );

    if (quizData.questions[i].type == "multipleChoice") {
      for (let j = 0; j < quizData.questions[i].answers.length; j++) {
        const letter = getLetterFromNumber(j + 1);

        docChildren.push(
          new Paragraph({
            children: [
              new TextRun({
                text: `${letter}. ${quizData.questions[i].answers[j]}`,
              }),
            ],
          })
        );
      }
    } else if (quizData.questions[i].type == "trueFalse") {
      docChildren.push(
        new Paragraph({
          children: [
            new TextRun({
              text: "A. True",
            }),
          ],
        })
      );
      docChildren.push(
        new Paragraph({
          children: [
            new TextRun({
              text: "B. False",
            }),
          ],
        })
      );
    }
    else if (quizData.questions[i].type == "fillIn") {
      docChildren.push(
        new Paragraph({
          children: [
            new TextRun({
              text: "_______________________________",
            }),
          ],
        })
      );
    }
  }

  const doc = new Document({
    sections: [
      {
        children: docChildren,
      },
    ],
  });

  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, "quijenQuiz.docx");
  });
};

export default SaveDoc;
