import { Box } from "@chakra-ui/react";
import ContentEditable from "react-contenteditable";

export const AnswerKey = ({ quizData, subscribed, setQuiz }) => {
  function handleAnswerChange(e, answerPrefix, questionIndex) {
    quizData.questions[questionIndex] = {
      index: questionIndex,
      value: e.target.value,
    };

    setQuiz({
      ...quizData,
    });
  }

  return (
    <Box p={"25px"} id="answerKeyOutput">
      {quizData?.questions?.map((question, index) => {
        let answer = question.correctAnswer;

        let answerPrefix = "";
        let answerValue = "";

        if (question.type == "multipleChoice") {
          let letter = "A";
          if (answer.index === 0) letter = "A";
          else if (answer.index === 1) letter = "B";
          else if (answer.index === 2) letter = "C";
          else if (answer.index === 3) letter = "D";
          else if (answer.index === 4) letter = "E";

          answerPrefix = `${index + 1}. ${letter}. - `;
          answerValue = answer.value
        }
        else if (question.type == "trueFalse") {
          answerPrefix = `${index + 1}. `;
          answerValue = answer.value ? "True" : "False";
        }
        else if (question.type == "fillIn") {
          answerPrefix = `${index + 1}. `;
          answerValue = answer
        }

        return (
          <Box key={"answers " + index + answerValue}>
            {answerPrefix}
            <ContentEditable
              style={{ display: "inline" }}
              key={"answers " + index + answerValue}
              html={answerValue.toString()}
              disabled={!subscribed}
              onChange={(e) => handleAnswerChange(e, answerPrefix, index)}
            />
          </Box>
        );
      })}
    </Box>
  );
};
