export function getLetterFromNumber(number) {
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    // Check if the number is within the valid range
    if (number < 1 || number > 26) {
        return "Invalid number. Please enter a number between 1 and 26.";
    }
    // Subtract 1 because array indices start at 0
    return alphabet[number - 1];
}

export const states = [
    "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", "Georgia",
    "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland",
    "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey",
    "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina",
    "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"
  ];