import {
  Box,
  Button,
  HStack,
  StackItem,
  Center,
  Select,
  Slide,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Text,
} from "@chakra-ui/react";

import { useState } from "react";
import { Link } from "react-router-dom";
import { MdOutlineArrowRight, MdOutlineArrowDropDown } from "react-icons/md";

export default function Slide2({
  numQuestions,
  setNumQuestions,
  questionMix,
  multipleChoiceAnswerNum,
  setMultipleChoiceAnswerNum,
  setQuestionMix,
  index,
  subscribed
}) {
  const labelStyles = {
    mt: "10",
    fontSize: "16pt",
  };

  const [advancedSettingsOpen, setAdvancedSettingsOpen] = useState(false);

  const getOptions = (label = "multiple choice") => {
    return (
      <>
        <option value="all">
          all {label} questions
        </option>
        <option value="a lot">
          a lot of {label} questions
        </option>
        <option value="a few">
          a few {label} questions
        </option>
        <option value="0">
          no {label} questions
        </option>
      </>
    );
  };

  const handleChange = (e, type) => {
    if (e.target.value === "all") {
      if (type === "multipleChoice") {
        setQuestionMix({
          ...questionMix,
          multipleChoice: "all",
          trueFalse: "0",
          fillIn: "0",
        });
      } else if (type === "trueFalse") {
        setQuestionMix({
          ...questionMix,
          trueFalse: "all",
          multipleChoice: "0",
          fillIn: "0",
        });
      } else if (type === "fillIn") {
        setQuestionMix({
          ...questionMix,
          fillIn: "all",
          trueFalse: "0",
          multipleChoice: "0",
        });
      }
    } else if (e.target.value === 0) {
      if(type === "multipleChoice" && questionMix.trueFalse === "0" && questionMix.fillIn === "0")
        setQuestionMix({
          ...questionMix,
          multipleChoice: 0,
          trueFalse: "all",
        });
      else if(type === "trueFalse" && questionMix.multipleChoice === "0" && questionMix.fillIn === "0")
        setQuestionMix({
          ...questionMix,
          trueFalse: 0,
          multipleChoice: "all",
        });
      else if(type === "fillIn" && questionMix.multipleChoice === "0" && questionMix.trueFalse === "0")
        setQuestionMix({
          ...questionMix,
          fillIn: 0,
          multipleChoice: "all",
        });
      else
        setQuestionMix({
          ...questionMix,
          [type]: "0",
        });
    } else {
      if(type !== "multipleChoice" && questionMix.multipleChoice === "all")
        setQuestionMix({
          ...questionMix,
          multipleChoice: "0",
          [type]: e.target.value,
        });
      else if(type !== "trueFalse" && questionMix.trueFalse === "all")
        setQuestionMix({
          ...questionMix,
          [type]: e.target.value,
          trueFalse: "0",
        });
      else if(type !== "fillIn" && questionMix.fillIn === "all")
        setQuestionMix({
          ...questionMix,
          [type]: e.target.value,
          fillIn: "0",
        })
      else
        setQuestionMix({
          ...questionMix,
          [type]: e.target.value,
        });
    }
  };

  return (
    <Slide
      in={(index === 2).toString()}
      out={(index !== 2).toString()}
      style={{ position: "relative" }}
      direction="right"
    >
      <Box width={"100%"} pr={"15%"} pl={"15%"}>
        <Box pt={"55px"} pb={"55px"} fontSize={"24pt"}>
          <Center>
            {numQuestions} question{numQuestions > 1 ? "s" : ""}
          </Center>
        </Box>
        <Slider
          onChange={(val) => setNumQuestions(val)}
          defaultValue={10}
          min={1}
          max={50}
          mb={"50px"}
        >
          <SliderMark
            value={1}
            {...labelStyles}
            onClick={() => setNumQuestions(1)}
          >
            0
          </SliderMark>
          <SliderMark
            value={10}
            {...labelStyles}
            onClick={() => setNumQuestions(10)}
          >
            10
          </SliderMark>
          <SliderMark
            value={20}
            {...labelStyles}
            onClick={() => setNumQuestions(20)}
          >
            20
          </SliderMark>
          <SliderMark
            value={30}
            {...labelStyles}
            onClick={() => setNumQuestions(30)}
          >
            30
          </SliderMark>
          <SliderMark
            value={40}
            {...labelStyles}
            onClick={() => setNumQuestions(40)}
          >
            40
          </SliderMark>
          <SliderMark
            value={50}
            {...labelStyles}
            onClick={() => setNumQuestions(50)}
          >
            50
          </SliderMark>
          <SliderTrack>
            <SliderFilledTrack color="rgb(49, 130, 206)" />
          </SliderTrack>
          <SliderThumb
            color="rgb(49, 130, 206)"
            backgroundColor="rgb(49, 130, 206)"
          />
        </Slider>
        <Center>
          <Box mt={"55px"}>
            <Button
              onClick={() => setAdvancedSettingsOpen(!advancedSettingsOpen)}
              leftIcon={
                advancedSettingsOpen ? (
                  <MdOutlineArrowDropDown />
                ) : (
                  <MdOutlineArrowRight />
                )
              }
            >
              Advanced Settings
            </Button>
          </Box>
        </Center>
      </Box>
      {advancedSettingsOpen && (
        <>
          {!subscribed && (
            <Box mt={"55px"}>
              <Center>
                <Text fontSize={["md", "lg"]}>
                  <Link to="/pricing" style={{ color: "rgb(49, 130, 206)", textDecoration: "underline" }} >
                    Subscribe
                  </Link>{" "}
                  to unlock advanced settings.
                </Text>
              </Center>
            </Box>
          )}
          <Box mt={"55px"} pl={"5%"} pr={"5%"}>
            <Box>
              <Center>
                <HStack wrap={"wrap"} display={"inline"}>
                  <StackItem mb={"5px"} mr={"5px"}>
                    <Center>The quiz should have</Center>
                  </StackItem>
                  <StackItem>
                    <Select
                      disabled={!subscribed}
                      width={"auto"}
                      mb={"5px"}
                      style={{ display: "inline" }}
                      onChange={(e) => handleChange(e, "multipleChoice")}
                      value={questionMix.multipleChoice}
                    >
                      {getOptions("multiple choice")}
                    </Select>
                  </StackItem>
                  <StackItem ml={"5px"} mr={"5px"}>
                    ,
                  </StackItem>
                  <StackItem mb={"5px"}>
                    <Select
                      disabled={!subscribed}
                      width={"auto"}
                      onChange={(e) => handleChange(e, "trueFalse")}
                      value={questionMix.trueFalse}
                    >
                      {getOptions("true/false")}
                    </Select>
                  </StackItem>
                  <StackItem ml={"5px"} mr={"5px"}>
                    and
                  </StackItem>
                  <StackItem>
                    <Select
                      disabled={!subscribed}
                      mb={"5px"}
                      width={"auto"}
                      onChange={(e) => handleChange(e, "fillIn")}
                      value={questionMix.fillIn}
                    >
                      {getOptions("fill-in-the-blank")}
                    </Select>
                  </StackItem>
                  <StackItem ml={"5px"} mr={"5px"}>
                    .
                  </StackItem>
                </HStack>
              </Center>
            </Box>
            <Box mt={"25px"}>
              <Center>
                <HStack wrap={"wrap"} display={"inline"}>
                  <StackItem>Multiple choice questions should have</StackItem>
                  <StackItem ml={"5px"} mr={"5px"}>
                    <Select
                      disabled={!subscribed}
                      onChange={(e) =>
                        setMultipleChoiceAnswerNum(e.target.value)
                      }
                      value={multipleChoiceAnswerNum}
                    >
                      <option value="3">3 possible answers.</option>
                      <option value="4">
                        4 possible answers.
                      </option>
                      <option value="5">5 possible answers.</option>
                      <option value="6">6 possible answers.</option>
                      <option value="7">7 possible answers.</option>
                      <option value="8">8 possible answers.</option>
                      <option value="9">9 possible answers.</option>
                      <option value="10">10 possible answers.</option>
                    </Select>
                  </StackItem>
                </HStack>
              </Center>
            </Box>
          </Box>
        </>
      )}
    </Slide>
  );
}
