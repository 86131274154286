import { useColorModeValue } from "@chakra-ui/color-mode";
import { Box, StackItem, Stack, VStack, Show } from "@chakra-ui/layout";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Center,
  Flex,
  Heading,
  HStack,
  Spinner,
  Text,
  Tooltip,
} from "@chakra-ui/react";

import { Step, Steps, useSteps } from "chakra-ui-steps";
import React, { useRef, useState, useEffect, useContext } from "react";
import { Quiz } from "../quiz";
import { AnswerKey } from "../answerKey";
import {
  FaPrint,
  FaRegFileWord,
  FaRegFilePdf,
  FaCheckCircle,
} from "react-icons/fa";
import { RxReset } from "react-icons/rx";
import { TbReload } from "react-icons/tb";
import { useReactToPrint } from "react-to-print";
import SaveDoc from "./renderedDoc";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { GetGens, IncrementGens } from "./gate";
import "./override.css";

import { getQuiz } from "./getQuiz";
import Slide0 from "./slide0";
import Slide1 from "./slide1";
import Slide2 from "./slide2";
import Slide3 from "./slide3";
import PDFQuiz from "./renderedPdf";
import WizNav from "./wizNav";

import { SubscriptionContext } from "../../App";

const steps = [
  { label: "Level" },
  { label: "Topic" },
  { label: "Length" },
  { label: "Difficulty" },
];

export const Wizard = ({ variant }) => {
  const [gens, setGens] = useState(0);
  const { subscribed, setSubscribed } = useContext(SubscriptionContext);

  useEffect(() => {
    GetGens(setGens);
  }, []);

  const totalReset = () => {
    reset();
    setLevel("elementary");
    setTopic("");
    setPdf(null);
    setNumQuestions(10);
    setQuestionMix({
      multipleChoice: 'all',
      trueFalse: '0',
      fillIn: '0',
    });
    setMultipleChoiceAnswerNum(4);
    setDifficulty("easy");
    setQuiz(null);
    setLoading(false);
    setReadingLevel('5th grade');
    setStateStandards('California');
  };

  const { nextStep, prevStep, reset, activeStep } = useSteps({
    initialStep: 0,
  });

  const isLastStep = activeStep === steps.length - 1;
  const hasCompletedAllSteps = activeStep === steps.length;

  const bg = useColorModeValue("gray.200", "gray.700");

  const [level, setLevel] = useState("elementary");
  const [topic, setTopic] = useState("");
  const [pdf, setPdf] = useState(null);
  const [numQuestions, setNumQuestions] = useState(10);
  const [difficulty, setDifficulty] = useState("easy");
  const [quiz, setQuiz] = useState(null);
  const [loading, setLoading] = useState(false);
  const [firstUse, setFirstUse] = useState(true);
  const [readingLevel, setReadingLevel] = useState('5th grade');
  const [stateStandards, setStateStandards] = useState('California');

  const [questionMix, setQuestionMix] = useState({
    multipleChoice: 'all',
    trueFalse: '0',
    fillIn: '0',
  });

  const [multipleChoiceAnswerNum, setMultipleChoiceAnswerNum] = useState(4);

  const quizRef = useRef();
  const answerKeyRef = useRef();

  const navigate = useNavigate();

  const handleQuizPrint = useReactToPrint({
    content: () => quizRef.current,
  });

  const handleAnswerKeyPrint = useReactToPrint({
    content: () => answerKeyRef.current,
  });

  useEffect(() => {
    //if (hasCompletedAllSteps)
    //  getQuiz(level, topic, numQuestions, difficulty, setQuiz, setLoading);
  }, [hasCompletedAllSteps]);

  useEffect(() => {
    if (quiz) setLoading(false);
  }, [quiz]);

  if (gens > 10 && !subscribed) {
    return (
      <>
        <Box mt={"50px"}>
          <Center>
            <VStack>
              <StackItem>
                <Heading size={"md"}>
                  You have exceeded the number of quizzes you can create.
                </Heading>
              </StackItem>
              <StackItem>
                <Text fontSize={"xl"}>
                  Please subscribe to access unlimited quizzes.
                </Text>
              </StackItem>
              <StackItem>
                <Button
                  colorScheme="green"
                  mt={4}
                  onClick={() => navigate("/pricing")}
                >
                  Subscribe
                </Button>
              </StackItem>
            </VStack>
          </Center>
        </Box>
      </>
    );
  }

  return (
    <Box
      mt={["0px", "0px", "25px"]}
      mb={"25px"}
      fontFamily={"Roboto"}
      mr={["1%", "1%", "0%"]}
      ml={["1%", "1%", "0%"]}
      id="wizardBox"
    >
      <Flex flexDir="column" width="100%">
        <Box width={"100%"}>
          <Steps
            variant={variant}
            colorScheme={hasCompletedAllSteps ? "green" : "blue"}
            responsive={true}
            activeStep={activeStep}
            fontFamily={"Roboto"}
          >
            {steps.map(({ label }, index) => (
              <Step label={label} key={label}>
                {
                  {
                    0: (
                      <Slide0
                        setLevel={setLevel}
                        level={level}
                        index={index}
                        prevStep={prevStep}
                        nextStep={nextStep}
                        firstUse={firstUse}
                        setFirstUse={setFirstUse}
                      />
                    ),
                    1: (
                      <Slide1
                        topic={topic}
                        setTopic={setTopic}
                        setPdf={setPdf}
                        index={index}
                      />
                    ),
                    2: (
                      <Slide2
                        numQuestions={numQuestions}
                        setNumQuestions={setNumQuestions}
                        setQuestionMix={setQuestionMix}
                        multipleChoiceAnswerNum={multipleChoiceAnswerNum}
                        setMultipleChoiceAnswerNum={setMultipleChoiceAnswerNum}
                        questionMix={questionMix}
                        index={index}
                        subscribed={subscribed}
                      />
                    ),
                    3: (
                      <Slide3
                        difficulty={difficulty}
                        setDifficulty={setDifficulty}
                        index={index}
                        prevStep={prevStep}
                        nextStep={nextStep}
                        subscribed={subscribed}
                        readingLevel={readingLevel}
                        setReadingLevel={setReadingLevel}
                        stateStandards={stateStandards}
                        setStateStandards={setStateStandards}
                        level={level}
                      />
                    ),
                    4: (
                      <Box mt={"50px"}>
                        <Center></Center>
                      </Box>
                    ),
                  }[index]
                }
                <WizNav
                  hasCompletedAllSteps={hasCompletedAllSteps}
                  reset={reset}
                  activeStep={activeStep}
                  prevStep={prevStep}
                  nextStep={nextStep}
                  isLastStep={isLastStep}
                />
              </Step>
            ))}
          </Steps>
        </Box>
        {hasCompletedAllSteps && (
          <>
            <Box
              sx={{
                bg,
                my: [2, 2, 8],
                p: [8, 8, 8],
                mx: ["1%"],
                rounded: "md",
              }}
            >
              <Heading
                fontSize={{ base: "2xl", md: "3xl" }}
                textAlign={"center"}
              >
                {!quiz
                  ? "Woohoo! All steps completed! 🎉"
                  : `A Quiz on '${topic}'`}
              </Heading>
              {loading ? null : (
                <>
                  <Box pt={"25px"}>
                    <Center>
                      <HStack gap={2} justify={"center"} wrap="wrap">
                        <Button
                          leftIcon={<TbReload />}
                          fontSize={{ base: "lg", md: "2xl" }}
                          size={"lg"}
                          colorScheme={"green"}
                          onClick={() => {
                            setLoading(true);
                            getQuiz(
                              level,
                              topic,
                              numQuestions,
                              difficulty,
                              pdf,
                              setQuiz,
                              setLoading,
                              setGens,
                              IncrementGens,
                              questionMix,
                              multipleChoiceAnswerNum,
                              readingLevel,
                              stateStandards
                            );
                          }}
                        >
                          {quiz ? "Re-generate Quiz" : "Generate Quiz"}
                        </Button>
                        <Button
                          colorScheme={"red"}
                          size={"lg"}
                          fontSize={{ base: "lg", md: "2xl" }}
                          leftIcon={<RxReset />}
                          onClick={() => totalReset()}
                        >
                          Make New Quiz
                        </Button>
                      </HStack>
                    </Center>
                  </Box>
                </>
              )}
            </Box>
            {loading ? (
              <Center>
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              </Center>
            ) : null}
            {quiz ? (
              <Center>
                <VStack>
                  <StackItem>
                    <Box mt={"20px"}>
                      <HStack gap={2} justify={"center"} wrap="wrap">
                        <Button
                          colorScheme="blue"
                          isDisabled={!subscribed}
                          onClick={() => SaveDoc(quiz)}
                          leftIcon={<FaRegFileWord />}
                        >
                          {subscribed ? (
                            "Download Word .DOCX"
                          ) : (
                            <Tooltip label="Subscribe to download a Word Document">
                              Download Word .DOCX
                            </Tooltip>
                          )}
                        </Button>
                        <PDFDownloadLink
                          document={<PDFQuiz quizData={quiz} />}
                          fileName="quizgen_quiz.pdf"
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? (
                              "Loading PDF..."
                            ) : (
                              <Button
                                leftIcon={<FaRegFilePdf />}
                                isDisabled={!subscribed}
                                backgroundColor="red.800"
                                color="white"
                                _hover={{ bgColor: "red.900" }}
                              >
                                {subscribed ? (
                                  "Download PDF"
                                ) : (
                                  <Tooltip label="Subscribe to download a PDF">
                                    Download PDF
                                  </Tooltip>
                                )}
                              </Button>
                            )
                          }
                        </PDFDownloadLink>
                        <Button
                          backgroundColor="gray.600"
                          color="white"
                          _hover={{ bgColor: "gray.700" }}
                          onClick={() => handleQuizPrint()}
                          leftIcon={<FaPrint />}
                        >
                          Print Quiz
                        </Button>
                        <Button
                          backgroundColor="orange.600"
                          _hover={{ bgColor: "orange.700" }}
                          color="white"
                          onClick={() => handleAnswerKeyPrint()}
                          leftIcon={<FaCheckCircle />}
                        >
                          Print Answer Key
                        </Button>
                      </HStack>
                    </Box>
                  </StackItem>
                  <StackItem>
                    {subscribed && (
                      <Box mt={"20px"}>
                        <HStack gap={2} justify={"center"} wrap="wrap">
                          <StackItem>
                            <span style={{ fontWeight: "bold" }}>
                              Here's a Tip -{" "}
                            </span>
                            You can edit the quiz on this page.
                          </StackItem>
                          <StackItem>
                            Just select what you want to change and type!
                          </StackItem>
                        </HStack>
                      </Box>
                    )}
                  </StackItem>
                </VStack>
              </Center>
            ) : null}
            <Box ref={quizRef} mt={"30px"}>
              <div>
                {quiz ? (
                  <>
                    <Quiz quizData={quiz} setQuiz={setQuiz} />{" "}
                  </>
                ) : null}
              </div>
            </Box>
            {quiz ? (
              <Box sx={{ bg, my: 8, p: 8, rounded: "md" }}>
                <Box ref={answerKeyRef} p={5}>
                  <Heading size="sm">Answer Key</Heading>{" "}
                  <AnswerKey
                    quizData={quiz}
                    subscribed={subscribed}
                    setQuiz={setQuiz}
                  />
                </Box>
              </Box>
            ) : null}
          </>
        )}
      </Flex>
    </Box>
  );
};

export default Wizard;
