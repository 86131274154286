import axios from "axios";

export function getQuiz(
  level,
  topic,
  numQuestions,
  difficulty,
  file,
  setQuiz,
  setLoading,
  setGens,
  IncrementGens,
  questionMix,
  multipleChoiceAnswerNum,
  readingLevel,
  stateStandards
) {
  // setLoading(true);
  setQuiz(null);
  let formData = new FormData();

  if (file) {
    formData.append("file", file);
  }

  formData.append("level", level);
  formData.append("topic", topic);
  formData.append("numQuestions", numQuestions);
  formData.append("difficulty", difficulty);

  formData.append("readingLevel", readingLevel);
  formData.append("stateStandards", stateStandards);

  for (const key in questionMix) {
    formData.append(key, questionMix[key]);
  }

  formData.append("multipleChoiceAnswerNum", multipleChoiceAnswerNum);
  try {
    axios
      .post(process.env.REACT_APP_BACKEND_URL + "/quiz/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        let questions = response.data;
        let answers = [];

        for (let i = 0; i < questions.length; i++) {
          answers.push({
            index: questions[i].correctAnswer.index,
            value: questions[i].correctAnswer.value,
          });
        }

        setQuiz({ questions, answers });
        IncrementGens(setGens);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  } catch (error) {
    console.error(error);
    setLoading(false);
  }
}
