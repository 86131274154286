import React from 'react';
import { FeatureCard } from './FeatureCard';
import './Features.css';
import { FaBolt, FaEdit, FaFileExport } from "react-icons/fa";
import { LuBrainCog } from "react-icons/lu";
import { FaBuildingCircleCheck } from "react-icons/fa6";

const features = [
  {
    icon: <FaBolt />,
    title: 'Instant Creation',
    description: 'Generate comprehensive quizzes in seconds using our advanced AI technology.'
  },
  {
    icon: <FaEdit />,
    title: 'Easy Editing',
    description: 'Click and edit any question or answer instantly with our intuitive interface.'
  },
  {
    icon: <FaFileExport />,
    title: 'Multiple Formats',
    description: 'Export your quizzes as PDF, Word documents, or print directly from the browser.'
  },
  {
    icon: <LuBrainCog />,
    title: 'Use Your Materials',
    description: '"Feed" Quijen your course materials and it will learn from them.  It can even listen to audiobooks or read your handwritten notes.'
  },
  {
    icon: <FaBuildingCircleCheck />,
    title: 'Adheres to Standards',
    description: 'Upload a book or study-guide approved by your district and Quijen will use it to generate your assessment.'
  }
];

export function Features() {
  return (
    <section id="features" className="features">
      <div className="features-grid">
        {features.map((feature, index) => (
          <FeatureCard key={index} {...feature} />
        ))}
      </div>
    </section>
  );
}