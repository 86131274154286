import { Box, Heading, Slide, Text, Center } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import StudentLevel from "../studentLevel";

export default function Slide0({ setLevel, level, index, nextStep, prevStep, firstUse, setFirstUse }) {
  return (
    <Slide
      in={(index === 0).toString()}
      out={(index !== 0).toString()}
      style={{ position: "relative" }}
      direction="right"
    >
      <Box mt={"45px"}>
        <Box>
          <Center>
            <Heading size={"lg"}>Let's make a quiz!</Heading>
          </Center>
        </Box>
        <Box mt={"25px"} mb={"25px"}>
          <Center>
            <Heading size={"md"}>
              What is the grade level of the quiz taker?
            </Heading>
          </Center>
        </Box>
        <Center>
          <StudentLevel
            setLevel={setLevel}
            level={level}
            nextStep={nextStep}
            prevStep={prevStep}
            setFirstUse={setFirstUse}
          />
        </Center>
      </Box>
      <Center>
        <Box mt={"55px"}>
          {firstUse && <Text fontSize={"sm"} color={"gray.500"}>
            By using Quijen, you agree to our{" "}
            <Link to="/terms" style={{ textDecoration: "underline" }}>Terms of Service</Link> and{" "}
            <Link to="/privacy" style={{ textDecoration: "underline" }}>Privacy Policy</Link>.
          </Text>}
        </Box>
      </Center>
    </Slide>
  );
}
