import React from "react";
import "./About.css";

export function About() {
  return (
    <section id="about" className="about">
      <h2>Made for America's Educators</h2>
      <p>
        Quijen is your AI-powered companion for creating engaging quizzes and
        assessments in less than a minute using materials -you- provide. Give us your study
        guides, books, and worksheets and watch us magically turn them into
        quality, challenging assessments. Save time and focus on what matters
        most - teaching.
      </p>
      <br />
      <p>
        Quijen can generate true/false, multiple choice, and fill-in-the-blank
        questions for students ranging from 1st graders to post-doctoral fellow.
        Quiz questions and answers can be Assessments can be exported as PDFs
        and Word Documents. Soon, Quijen will evolve into a fully realized
        learning platform with grading and analytics.
      </p>
    </section>
  );
}
