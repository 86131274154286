import React, { useContext } from "react";
import { Box, Heading } from "@chakra-ui/react";
import ContentEditable from "react-contenteditable";
import { SubscriptionContext } from "../../App";

export default function TrueFalseQ({ question, index, quiz, setQuiz }) {
  let questionHeading = question.question;
  const { subscribed, setSubscribed } = useContext(SubscriptionContext);

  function handleQuestionChange(e, questionIndex) {
    question.question = e.target.value;

    setQuiz({
      ...quiz,
      questions: [
        ...quiz.questions.slice(0, questionIndex),
        question,
        ...quiz.questions.slice(questionIndex + 1),
      ],
    });
  }

  return (
    <Box key={index} mb={"25px"}>
      <Heading size="md">
        {index + 1}.{" "}
        <ContentEditable
          html={questionHeading}
          disabled={!subscribed}
          tagName="span"
          onChange={(e) => handleQuestionChange(e, index)}
        />
      </Heading>
      <Box key={`question ${index} answer 1`}>A - True</Box>
      <Box key={`question ${index} answer 0`}>B - False</Box>
    </Box>
  );
}
