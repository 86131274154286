import { Box, Heading, Link, Text } from "@chakra-ui/react";

export default function Support() {
  return (
    <Box mt={10} mx={"auto"} w={["80%", "50%"]} fontFamily={"Roboto"}>
      <Heading as="h1" fontSize="4xl" mb={4}>
        We're Here to Help You
      </Heading>
      <Text>
        If you have any questions or concerns, please contact us via e-mail at{" "}
        <Link href="mailto:help@quijen.com">help@quijen.com</Link>.
      </Text>
      <Heading as="h1" fontSize="4xl" mt={8}>
        Who We Are
      </Heading>
      <Text mt={4}>
        Quijen is an AI-powered quiz and assessment creator that helps ease the
        burden placed on today’s educators. Whether your students are in diapers or on
        their way to a thesis defense, Quijen is the right tool for challenging
        your students without wasting your time. We get you into the action without delay
        to let you generate quizzes without the hassle of slow dashboards and
        cumbersome links. Start clicking and creating in seconds from the moment
        you visit Quijen.com.
      </Text>
      <Text mt={4}>
        When you generate a quiz with Quijen, editing its contents is as easy as
        clicking on any given question or answer and making your corrections
        instantly. You can export your work as a PDF, a Word Document, or print it for
        student use directly from our interface. Soon, we hope to add the
        ability to export quizzes and tests to Canva, Visio, and other popular
        tools.
      </Text>
      <Text mt={4}>
        We are based in Oklahoma City, Oklahoma and take pride in supporting
        educators here in the U.S. and around the world. Our prices are low to
        ensure educators at all levels have access to our platform. It is our
        solemn commitment to continue to provide a cost-effective and useful
        tool to educators. The team at Quijen -treasures- your feedback. Please
        write us an e-mail at <Link href="mailto:help@quijen.com">help@quijen.com</Link> and tell us how we can improve our
        product. We adore our users and want to do everything we can to help you
        make the most your time. Thank you so much for visiting!
      </Text>
    </Box>
  );
}
