import React from "react";
import "./Hero.css";
import { Box, Heading, Center, Image } from "@chakra-ui/react";

export function Hero() {
  return (
    <>
      <section className="hero">
        <Box width={"100%"} mb={"55px"} mt={"55px"}>
          <Center>
            <Image src="favicon.svg" alt="Quijen Logo" mr={4} />
          </Center>
        </Box>
        <Box className="hero-content" width={"100%"}>
          <Box width={"100%"} mb={"55px"}>
            <Center>
              <Heading size={{ base: "xl", md: "2xl" }}>Create A Quiz in Seconds</Heading>
            </Center>
          </Box>
          <Box fontSize={["xl", "2xl"]}>
            <Center>
              Quijen is your AI-powered companion for creating engaging quizzes
              and assessments. Give us your study guides and watch us magically turn it into quality, challenging assessments.  Save time and focus on what matters most -
              teaching.
            </Center>
          </Box>
          <Box mt={"55px"}>
            <Center>
              <a href="/" className="cta-button" fontSize={{ base: "lg", md: "2xl" }}>
                Start Creating Now
              </a>
            </Center>
          </Box>
        </Box>
      </section>
    </>
  );
}
